import React from 'react';
import { useForm } from 'react-hook-form';

import { Box, Typography } from '@mui/material';
import { Button } from 'component/Button/Button';
import { useOnMfaVerificationSubmit } from 'shared/hooks/useOnMfaVerificationSubmit';
import { VerificationCodeField } from 'component/VerificationCodeField/VerificationCodeField';
import { VerificationCodeFieldValues } from 'component/VerificationCodeField/constants';

const inputsCount = 6;
const defaultValues: VerificationCodeFieldValues = { code: [] };

export const VerifyMfaPage: React.VFC = () => {
  const {
    control,
    handleSubmit,
    trigger: triggerValidation,
    formState: { isSubmitting },
  } = useForm<VerificationCodeFieldValues>({ defaultValues });

  const onMfaVerificationSubmit = useOnMfaVerificationSubmit();

  const nextElementToFocusRef = React.useRef<HTMLButtonElement>(null);

  const onSubmit = async ({ code }: VerificationCodeFieldValues) =>
    onMfaVerificationSubmit(code.join(''));

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ maxWidth: { xs: '400px', md: '500px' }, margin: 'auto' }}
    >
      <Typography data-testid="verifyMfaHeader" variant="h1" mb={1}>
        Enter your code
      </Typography>
      <Typography data-testid="verifyMfaDescription" variant="body1" mb={3}>
        Please enter the 6 digit code from your multi factor authentication app or browser plugin.
      </Typography>

      <Box mb={3}>
        <VerificationCodeField
          control={control}
          inputsCount={inputsCount}
          triggerValidation={triggerValidation}
          nextElementToFocusRef={nextElementToFocusRef}
          data-testid="verificationCode"
          disabled={isSubmitting}
        />
      </Box>

      <Button
        ref={nextElementToFocusRef}
        disabled={isSubmitting}
        type="submit"
        fullWidth
        variant="contained"
        data-testid="verifyMfaButton"
      >
        Confirm
      </Button>
    </Box>
  );
};
