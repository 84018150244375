import React from 'react';

import { useFetchGrowthbook } from 'shared/hooks/useFetchGrowthbook';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { growthbook } from 'config/growthbook';

type WBGrowthBookProviderProps = {
  children: JSX.Element;
};

export const WBGrowthBookProvider: React.VFC<WBGrowthBookProviderProps> = ({ children }) => {
  const { loading } = useFetchGrowthbook();
  return (
    <>{!loading && <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>}</>
  );
};
