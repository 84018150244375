import { AxiosInstance } from 'axios';
import { SSOProviderEnum } from 'shared/enums/SSOProviderEnum';

type GetAvailableSSOProvidersRequestDto = {
  email: string;
  uiAppBaseUrl: string;
};

export type AvailableSSOProviderDto = {
  provider: SSOProviderEnum;
  link: string;
};

export const getAvailableSSOProvider =
  (client: AxiosInstance) =>
  (data: GetAvailableSSOProvidersRequestDto): Promise<AvailableSSOProviderDto[]> => {
    return client
      .post<{ data: { providers: AvailableSSOProviderDto[] } }>('/api/sso/providers', data)
      .then((response) => response.data)
      .then(({ data }) => data.providers)
      .catch((error) => {
        throw error.response;
      });
  };
