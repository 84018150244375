import * as React from 'react';
import { Route, Switch } from 'react-router';

import {
  LOGIN_PATTERN,
  OAUTH_CALLBACK,
  RESET_PASSWORD,
  RESET_PASSWORD_REQUEST,
  SET_PASSWORD,
  SETUP_MFA,
  VERIFY_MFA,
} from 'app/route';
import { ResetPasswordRequestPage } from 'view/ResetPasswordRequestPage/ResetPasswordRequestPage';
import { ResetPasswordPage } from 'view/ResetPasswordPage/ResetPasswordPage';
import { SetPasswordPage } from 'view/SetPasswordPage/SetPasswordPage';
import { PageWrapper } from 'component/PageWrapper/PageWrapper';
import { SetupMfaPage } from 'view/SetupMfaPage/SetupMfaPage';
import { VerifyMfaPage } from 'view/VerifyMfaPage/VerifyMfaPage';
import { LoginFormPage } from 'view/LoginFormPage/LoginFormPage';
import { OAuthCallbackPage } from 'view/OAuthCallbackPage/OAuthCallbackPage';
import { PageWrapperWithFeedback } from 'component/PageWrapperWithFeedback/PageWrapperWithFeedback';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useInitializeRUM } from 'shared/hooks/useInitializeRUM';
import { useInitializeDataDogLogs } from 'shared/hooks/useInitializeDataDogLogs';

export const AppView: React.VFC = () => {
  const isG2ReviewEnabled = useFeatureIsOn('g2-review-enabled');
  const PageWrapperToUse = isG2ReviewEnabled ? PageWrapperWithFeedback : PageWrapper;

  useInitializeRUM();
  useInitializeDataDogLogs();

  return (
    <PageWrapperToUse>
      <Switch>
        <Route path={LOGIN_PATTERN} component={LoginFormPage} />
        <Route path={RESET_PASSWORD_REQUEST} component={ResetPasswordRequestPage} />
        <Route path={SET_PASSWORD} component={SetPasswordPage} />
        <Route path={RESET_PASSWORD} component={ResetPasswordPage} />
        <Route path={SETUP_MFA} component={SetupMfaPage} />
        <Route path={VERIFY_MFA} component={VerifyMfaPage} />
        <Route path={OAUTH_CALLBACK} component={OAuthCallbackPage} />
        <Route render={() => <div data-testid="notFoundPage">Not found</div>} />
      </Switch>
    </PageWrapperToUse>
  );
};
