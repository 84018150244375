import React from 'react';

import { Box, Typography } from '@mui/material';

import { authorStyles, figureStyles, quoteStyles, titleStyles } from './Quote.s';

type QuoteProps = {
  author: string;
  color?: string;
  quote: string;
  title?: string;
};

export const Quote: React.VFC<QuoteProps> = (props) => {
  const { author, color, quote, title } = props;
  return (
    <Box color={color && color} component="figure" {...props} sx={figureStyles}>
      {title && (
        <Typography component="span" sx={titleStyles}>
          {title}
        </Typography>
      )}
      <Typography component="blockquote" sx={quoteStyles}>
        {quote}
      </Typography>
      <Typography component="figcaption" variant="body1" sx={authorStyles}>
        {author}
      </Typography>
    </Box>
  );
};
