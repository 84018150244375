import * as React from 'react';
import { Router } from 'react-router';

import { AppView } from 'view/AppView/AppView';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'component/SnackbarProvider/SnackbarProvider';
import history from 'app/history';
import { theme } from 'app/theme/theme';
import { useInitializeGTM } from 'shared/hooks/useInitializeGTM';
import { WBGrowthBookProvider } from 'component/WBGrowthBookProvider/WBGrowthBookProvider';
import { useCleanPosthogCookie } from 'shared/hooks/useCleanPosthogCookie';

export const App = () => {
  useInitializeGTM();
  useCleanPosthogCookie();

  return (
    <Router history={history}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <WBGrowthBookProvider>
            <AppView />
          </WBGrowthBookProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  );
};
