import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

export const useCleanPosthogCookie = () => {
  const [, , removeCookie] = useCookies(['isPostHogIdentified']);

  useEffect(() => {
    removeCookie('isPostHogIdentified', { path: '/' });
  }, [removeCookie]);
};
