import React from 'react';

import {
  SnackbarProvider as BaseSnackbarProvider,
  SnackbarProviderProps as BaseSnackbarProviderProps,
} from 'notistack';

import { SnackbarAlert } from './components/SnackbarAlert';

type SnackbarProviderProps = BaseSnackbarProviderProps;

export const SnackbarProvider: React.VFC<SnackbarProviderProps> = ({ children, ...props }) => (
  <BaseSnackbarProvider
    maxSnack={3}
    Components={{
      default: SnackbarAlert,
      error: SnackbarAlert,
      info: SnackbarAlert,
      success: SnackbarAlert,
      warning: SnackbarAlert,
    }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    {...props}
  >
    {children}
  </BaseSnackbarProvider>
);
