import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const figureStyles: SxProps<Theme> = {
  margin: 0,
};

const commonStyles: SxProps<Theme> = {
  color: 'inherit',
};

export const titleStyles: SxProps<Theme> = (theme) => ({
  ...commonStyles,
  display: 'block',
  lineHeight: theme.typography.pxToRem(24.4),
  marginBottom: theme.spacing(1),
  fontFamily: ['Zilla Slab', '-apple-system', 'BlinkMacSystemFont', 'Arial', 'sans-serif'].join(
    ','
  ),
  fontSize: theme.typography.pxToRem(19),
  fontWeight: theme.typography.fontWeightBold,
});

export const quoteStyles: SxProps<Theme> = (theme) => ({
  ...commonStyles,
  lineHeight: theme.typography.pxToRem(55.7),
  marginBottom: theme.spacing(3),
  fontFamily: ['Zilla Slab', '-apple-system', 'BlinkMacSystemFont', 'Arial', 'sans-serif'].join(
    ','
  ),
  fontSize: theme.typography.pxToRem(39),
  fontWeight: theme.typography.fontWeightBold,
});

export const authorStyles: SxProps<Theme> = {
  ...commonStyles,
  fontStyle: 'italic',
};
