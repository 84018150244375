import create from 'zustand';
import { AuthApi } from 'api/AuthApi';

export type LoginResponseState = {
  loginResponse: AuthApi.LoginEntry | null;
  setLoginResponse: (loginResponse: AuthApi.LoginEntry | null) => void;
};

export const useLoginResponseStore = create<LoginResponseState>((set) => ({
  loginResponse: null,
  setLoginResponse: (loginResponse) => set((state) => ({ ...state, loginResponse })),
}));
