import { isAdminUI, isReportUI } from 'app/route/hostname.js';
import { getAppConfig } from 'app/getConfig';

export const getGTMKey = () => {
  switch (true) {
    case isAdminUI:
      return getAppConfig().ADMIN_UI_GTM_KEY;
    case isReportUI:
      return getAppConfig().REPORT_UI_GTM_KEY;
    default:
      return '';
  }
};
