import React from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import { primaryLinkStyles } from 'component/Link/Link.s';
import { TextFieldController } from 'component/TextField/TextFieldController';
import { Alert, Box, Button, Link, Typography } from '@mui/material';
import { redirectAfterLogin } from 'shared/utils/redirect/redirectAfterLogin';
import { UNAUTHORIZED } from 'http-status-codes';
import { RESET_PASSWORD_REQUEST } from 'app/route';
import { authApi } from 'container/authApi';
import { password } from 'shared/utils/rules/password';
import { useLoginResponseStore } from 'store/loginResponseStore';
import { AvailableSSOProviderDto } from 'api/ssoProviderApi/getAvailableSSOProvider/getAvailableSSOProvider';
import { colors } from 'app/theme/config';

import { SSOButtonList } from './SSOButtonList/SSOButtonList';

type LoginFormProps = {
  email: string;
  availableSSOProviders: AvailableSSOProviderDto[];
  onResetEmail: () => void;
};

type LoginFormFieldValues = {
  email: string;
  password: string;
};

export const LoginForm: React.VFC<LoginFormProps> = ({
  email,
  availableSSOProviders,
  onResetEmail,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const setLoginResponse = useLoginResponseStore((state) => state.setLoginResponse);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<LoginFormFieldValues>({
    defaultValues: {
      email,
      password: '',
    },
  });

  const onSubmit = async (data: LoginFormFieldValues) => {
    const { email, password } = data;
    let token = undefined;

    authApi
      .login(email, password, token)
      .then((loginResponse) => {
        setLoginResponse(loginResponse);
        redirectAfterLogin(loginResponse);
      })
      .catch((error) => {
        if (error.status === UNAUTHORIZED && error.data?.error?.message) {
          enqueueSnackbar(error.data.error.message, { variant: 'error' });
        } else {
          enqueueSnackbar('Cannot login - error occurred.', { variant: 'error' });
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="loginForm" noValidate>
      <Box mb={3}>
        <Typography variant="h1">Welcome back</Typography>
      </Box>
      <SSOButtonList availableSSOProviders={availableSSOProviders} />
      <TextFieldController
        control={control}
        label={
          <Box
            component="span"
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <span>Email Address</span>
            <Typography
              component="span"
              variant="body2"
              sx={{ color: colors.oxfordBlue, cursor: 'pointer' }}
              onClick={onResetEmail}
              data-testid="useDifferentAccount"
            >
              Use a different account
            </Typography>
          </Box>
        }
        InputLabelProps={{
          sx: {
            width: '100%',
          },
        }}
        name="email"
        type="email"
        disabled
      />
      <TextFieldController
        control={control}
        label="Password"
        name="password"
        rules={password}
        type="password"
      />
      <Box mb={3}>
        <Link component={RouterLink} to={RESET_PASSWORD_REQUEST} sx={primaryLinkStyles}>
          Forgotten password?
        </Link>
      </Box>
      <Box mb={3}>
        <Alert icon={false} severity="info">
          By logging into the WorkBuzz platform you are agreeing to the{' '}
          <Link href="https://workbuzz.com/privacy-policy/" target="_blank">
            use of confidential third party cookies
          </Link>{' '}
          for the purposes of improving our services.
        </Alert>
      </Box>
      <Button disabled={isSubmitting} fullWidth type="submit">
        Continue
      </Button>
    </form>
  );
};
