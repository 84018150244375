import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';

import { Alert, Link, Typography } from '@mui/material';
import { LOGIN_PATTERN } from 'app/route';
import { usePostOAuthCallback } from 'shared/hooks/usePostOAuthCallback';

export const OAuthCallbackPage: React.VFC = () => {
  const { provider } = useParams<{ provider: string }>();
  const location = useLocation();

  const useQuery = () => {
    const { search } = location;
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();

  const { loading, error } = usePostOAuthCallback(
    provider,
    query.get('code'),
    query.get('state'),
    window.location.origin
  );

  return (
    <>
      {loading && <Typography>Logging in... Please do not refresh the page.</Typography>}
      {error && (
        <Alert data-testid="o-auth-callback-error" severity="error">
          There was an error. Please{' '}
          <Link component={RouterLink} to={LOGIN_PATTERN}>
            Login
          </Link>{' '}
          and try again.
        </Alert>
      )}
    </>
  );
};
