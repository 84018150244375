import { RegisterOptions } from 'react-hook-form';

import { createRequired } from 'shared/utils/rules/createRequired';

export const email: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'> = {
  pattern: {
    message: 'Please enter a correct email',
    value: /^[A-Z0-9._%\'+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  },
  ...createRequired('Please enter your email'),
};
