import React from 'react';

import { Box, Grid, Icon, useMediaQuery, useTheme } from '@mui/material';
import { common } from '@mui/material/colors';
import workbuzzLogo from 'app/asset/workbuzz_logo.svg';
import heroesImageXs from 'app/asset/heroes-xs.png';
import heroesImageMdPlus from 'app/asset/heroes-md+.png';
import { AddReviewContent } from 'component/AddReviewContent/AddReviewContent';

import {
  addReviewContentStyles,
  contentStyles,
  contentWrapperStyles,
  feedbackContentMobileStyles,
  feedbackWrapperMobileStyles,
  heroesImageStyles,
  logoStyles,
  rightColumnInnerStyles,
  rightColumnStyles,
} from './PageWrapperWithFeedback.s';

export const PageWrapperWithFeedback: React.FC = ({ children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Grid container data-testid="pageWrapperWithFeedback">
      <Grid xs={12} lg={6} item>
        <Box component="img" src={workbuzzLogo} sx={logoStyles} />
        <Box sx={contentWrapperStyles}>
          <Box sx={contentStyles}>{children}</Box>
        </Box>
        {!isDesktop && (
          <Box sx={feedbackWrapperMobileStyles} data-testid="feedbackWrapperMobile">
            <Box sx={feedbackContentMobileStyles}>
              <Icon sx={{ color: common.white }}>keyboard_arrow_up</Icon>
              <AddReviewContent />
              <Box component="img" src={heroesImageXs} sx={heroesImageStyles} />
            </Box>
          </Box>
        )}
      </Grid>
      {isDesktop && (
        <Grid lg={6} item sx={rightColumnStyles} data-testid="rightColumn">
          <Box sx={rightColumnInnerStyles}>
            <AddReviewContent sx={addReviewContentStyles} />
            <Box component="img" src={heroesImageMdPlus} sx={heroesImageStyles} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
