import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { useSnackbar } from 'notistack';
import { TextFieldController } from 'component/TextField/TextFieldController';
import { BAD_REQUEST } from 'http-status-codes';
import { Box, Button, Typography } from '@mui/material';
import { email } from 'shared/utils/rules/email';
import { authApi } from 'container/authApi';
import { AxiosResponse } from 'axios';

const defaultValues: FieldValues = {
  email: '',
};

export const ResetPasswordRequestPage: React.VFC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm({ defaultValues });

  const onSubmit = async (data: FieldValues) => {
    await authApi
      .resetPasswordRequest(data.email)
      .then(() => {
        reset(defaultValues);
        enqueueSnackbar('Email with reset password link was sent', { variant: 'success' });
      })
      .catch((error: AxiosResponse) => {
        if (error.status === BAD_REQUEST && error?.data?.error?.violations) {
          const multiLineMessage = error.data.error.violations
            .map((violation: { message: string }) => violation.message)
            .join('\n');
          enqueueSnackbar(multiLineMessage, {
            variant: 'error',
            SnackbarProps: { style: { whiteSpace: 'pre-line' } },
          });
        } else {
          enqueueSnackbar('Cannot send email - error occurred.', { variant: 'error' });
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box mb={5}>
        <Typography variant="h1">Reset Password To WorkBuzz</Typography>
      </Box>
      <TextFieldController
        control={control}
        label="Email Address"
        name="email"
        rules={email}
        type="email"
      />
      <Button disabled={isSubmitting} fullWidth type="submit">
        Send Email
      </Button>
    </form>
  );
};
