import React from 'react';

import { Box, ButtonBase } from '@mui/material';
import microsoftLogo from 'app/asset/microsoft-logo.svg';
import { buttonStyles, iconStyles } from 'view/LoginFormPage/SSOButtonList/MicrosoftSSOButton.s';

type MicrosoftSSOButtonProps = {
  link: string;
};

export const MicrosoftSSOButton: React.VFC<MicrosoftSSOButtonProps> = ({ link }) => {
  return (
    <ButtonBase sx={buttonStyles} href={link} data-testid="microsoftSSOButton">
      <Box component="img" src={microsoftLogo} sx={iconStyles} />
      Sign in with Microsoft
    </ButtonBase>
  );
};
