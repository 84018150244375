import React from 'react';

import { Box, Grid, GridProps } from '@mui/material';
import { Quote } from 'component/Quote/Quote';
import { common } from '@mui/material/colors';
import workbuzzLogo from 'app/asset/workbuzz_logo.svg';

import {
  componentWrapper,
  logoStyles,
  gridStyles,
  leftColumnStyles,
  leftColumnInnerStyles,
  rightColumnStyles,
  rightColumnInnerStyles,
} from './PageWrapper.s';

type PageWrapperProps = GridProps;

export const PageWrapper: React.FC<PageWrapperProps> = ({ children, ...props }) => (
  <Grid container {...props} sx={gridStyles}>
    <Grid xs={12} lg={6} item sx={leftColumnStyles}>
      <Box component="img" src={workbuzzLogo} sx={logoStyles} />
      <Grid container alignItems="center" justifyContent="center" sx={leftColumnInnerStyles}>
        <Grid item xs={12} sm={10}>
          <Box sx={componentWrapper}>{children}</Box>
        </Grid>
      </Grid>
    </Grid>
    <Grid display={{ xs: 'none', lg: 'block' }} item sm={6} sx={rightColumnStyles}>
      <Box sx={rightColumnInnerStyles}>
        <Quote
          author="University of Warwick Study"
          color={common.white}
          quote="Happy employees have been found to be 12% more productive"
          title="Did you know?"
        />
      </Box>
    </Grid>
  </Grid>
);
