import React from 'react';
import { Control, useController, UseFormTrigger } from 'react-hook-form';

import { InputBaseProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { VerificationCodeInput } from 'component/VerificationCodeField/VerificationCodeInput';
import {
  verificationCodeFieldRules,
  VerificationCodeFieldValues,
} from 'component/VerificationCodeField/constants';

export type VerificationCodeFieldProps = Omit<InputBaseProps, 'sx'> & {
  control: Control<VerificationCodeFieldValues>;
  triggerValidation: UseFormTrigger<VerificationCodeFieldValues>;
  inputsCount: number;
  disabled?: boolean;
  nextElementToFocusRef?: React.RefObject<HTMLElement>;
  sx?: SxProps<Theme>;
  'data-testid'?: string;
};

export const VerificationCodeField: React.VFC<VerificationCodeFieldProps> = ({
  control,
  triggerValidation,
  inputsCount,
  disabled,
  nextElementToFocusRef,
  sx,
  'data-testid': dataTestId,
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name: 'code', control, rules: verificationCodeFieldRules });

  const onPaste = () => triggerValidation();

  return (
    <VerificationCodeInput
      data-testid={dataTestId}
      disabled={disabled}
      error={!!error}
      errorMessage={error?.message}
      inputsCount={inputsCount}
      nextElementToFocusRef={nextElementToFocusRef}
      onBlur={onBlur}
      onChange={onChange}
      onPaste={onPaste}
      sx={sx}
      value={value}
    />
  );
};
