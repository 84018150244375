import React from 'react';

import { Box, BoxProps, Button, Icon, Typography } from '@mui/material';
import { common } from '@mui/material/colors';
import { colors } from 'app/theme/config';
import { mergeSx } from 'shared/utils/merge/mergeSx';

export const AddReviewContent: React.VFC<BoxProps> = ({ ...props }) => {
  return (
    <Box {...props} sx={mergeSx({ width: '100%', color: common.white }, props.sx)}>
      <Typography variant="h3" mb={{ xs: 7, lg: 2 }}>
        Happy with WorkBuzz? 🧡
      </Typography>
      <Typography variant="body2" mb={4}>
        Leave us a G2 review and claim your <strong>$25 Amazon gift card!</strong>
      </Typography>
      <Button
        fullWidth
        sx={{
          background: `${common.white} !important`,
          color: `${colors.blueRibbon} !important`,
          marginBottom: 2,
          maxWidth: { lg: 330 },
        }}
        endIcon={<Icon>open_in_new</Icon>}
        onClick={() => {
          window.open(
            'https://www.g2.com/contributor/workbuzz-25-reward-link?secure[page_id]=workbuzz-25-reward-link&secure[rewards]=true&secure[token]=a933e86e329ca7bbb04107463213a861daf3bfc1ed7e4dcc9b978d1fded07117&utm_source=workbuzz&utm_medium=loginpage&utm_campaign=2024',
            '_blank'
          );
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          YES I’m happy, leave a G2 review
        </Typography>
      </Button>

      <Typography variant="body2">
        <Box
          component="a"
          sx={{ color: common.white, textUnderlineOffset: '8px' }}
          href="https://share.hsforms.com/1FGZs0QetR1mXyNR0YU4IVQ41tm3"
          target="_blank"
        >
          Not there yet? Tell us where to improve
        </Box>
      </Typography>
    </Box>
  );
};
