import React, { useEffect, useRef } from 'react';

import {
  VerificationCodeFieldGroup,
  VerificationCodeInputStyled,
} from 'component/VerificationCodeField/VerificationCodeField.s';
import { ErrorMessage } from 'component/TextField/TextField.s';
import { InputBaseProps } from '@mui/material';

type VerificationCodeInputProps = Omit<InputBaseProps, 'onChange'> & {
  inputsCount: number;
  value: string[];
  onChange: (value: string[]) => void;
  onBlur: () => void;
  onPaste: () => void;
  nextElementToFocusRef?: React.RefObject<HTMLElement>;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  'data-testid'?: string;
};

export const VerificationCodeInput: React.VFC<VerificationCodeInputProps> = ({
  inputsCount,
  value,
  onChange,
  onBlur,
  onPaste,
  nextElementToFocusRef,
  disabled,
  error,
  errorMessage,
  sx,
  'data-testid': dataTestId,
}) => {
  const inputValue = Array(inputsCount)
    .fill('')
    .map((_, index) => value[index] ?? '');

  const inputRefs = useRef<HTMLInputElement[]>([]);

  const focusNextFormElement = (index: number) => {
    const nextIndex = index + 1;
    if (nextIndex < inputsCount) {
      inputRefs.current[nextIndex].focus();
      inputRefs.current[nextIndex].select();
    } else {
      nextElementToFocusRef?.current?.focus();
    }
  };

  const handlePaste = (clipboardText: string, index: number) => {
    const pastePart = clipboardText.substring(0, inputsCount - index);
    const newValue = [...value];
    for (let i = 0; i < pastePart.length; i++) {
      newValue[i + index] = pastePart[i];
    }
    onChange(newValue);
    onPaste();
  };

  useEffect(() => {
    if (error) {
      const firstEmptyInput = inputRefs.current.find((input) => input.value === '');
      setTimeout(() => firstEmptyInput?.focus(), 0);
    }
  }, [inputValue, error]);

  return (
    <>
      <VerificationCodeFieldGroup data-testid={dataTestId} sx={sx}>
        {inputValue.map((char, index) => (
          <VerificationCodeInputStyled
            key={index}
            onChange={(event) => {
              const newValue = [...value];
              newValue[index] = event.target.value;
              onChange(newValue);

              if (event.target.value) {
                focusNextFormElement(index);
              }
            }}
            onPaste={(element) => handlePaste(element.clipboardData.getData('text'), index)}
            onFocus={(event) => event.target.select()}
            onBlur={onBlur}
            value={char}
            error={error && !char}
            disabled={disabled}
            inputProps={{ inputMode: 'numeric', maxLength: 1 }}
            data-testid={`${dataTestId}Input[${index}]`}
            inputRef={(el) => (inputRefs.current[index] = el)}
          />
        ))}
      </VerificationCodeFieldGroup>
      {error && (
        <ErrorMessage data-testid={`${dataTestId}Error`} ownerState={{ disabled }}>
          {errorMessage}
        </ErrorMessage>
      )}
    </>
  );
};
