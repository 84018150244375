import { Box, inputBaseClasses, styled } from '@mui/material';
import { WBInput } from 'component/TextField/TextField.s';
import { mergeStrict } from 'shared/utils/merge/mergeStrict';

export const VerificationCodeFieldGroup = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const VerificationCodeInputStyled = styled(WBInput)(({ theme }) => ({
  color: theme.palette.text.primary,
  width: '51px',
  height: '66px',

  ...mergeStrict(theme.typography.h4, {
    [theme.breakpoints.up('md')]: {
      width: '65px',
      height: '85px',
    },
  }),
  [`& .${inputBaseClasses.input}`]: {
    textAlign: 'center',
  },
}));
