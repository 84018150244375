import React, { HTMLInputTypeAttribute } from 'react';
import { Control, FieldValues, RegisterOptions, useController } from 'react-hook-form';

import { TextField } from 'component/TextField/TextField';
import { FieldPath } from 'react-hook-form/dist/types';
import { InputLabelProps } from '@mui/material';

type TextFieldControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  control: Control<TFieldValues, object>;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  name: TName;
  label?: React.ReactNode;
  type?: HTMLInputTypeAttribute;
  disabled?: boolean;
  InputLabelProps?: InputLabelProps;
};

export const TextFieldController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  name,
  control,
  rules,
  type = 'text',
  disabled,
  InputLabelProps,
}: TextFieldControllerProps<TFieldValues, TName>): JSX.Element => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error, isDirty },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <TextField
      data-testid={name}
      error={!!error}
      errorMessage={error?.message}
      fullWidth
      isDirty={isDirty}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      type={type}
      value={value}
      disabled={disabled}
      InputLabelProps={InputLabelProps}
    />
  );
};
