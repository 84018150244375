import { AxiosInstance } from 'axios';
import { authApiClient } from 'container/authApiClient';

import { getAvailableSSOProvider } from './getAvailableSSOProvider/getAvailableSSOProvider';

const buildSSOProviderApi = (client: AxiosInstance) => ({
  getAvailableSSOProviders: getAvailableSSOProvider(client),
});

export const ssoProviderApi = buildSSOProviderApi(authApiClient);
