import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { getGTMKey } from 'app/ga/gaKeys';

export const useInitializeGTM = () => {
  useEffect(() => {
    if (getGTMKey()) {
      TagManager.initialize({
        gtmId: getGTMKey(),
      });
    }
  }, []);
};
