import { AuthApi } from 'api/AuthApi';
import history from 'app/history';
import { SETUP_MFA, VERIFY_MFA } from 'app/route';
import { redirectToApplication } from 'shared/utils/redirect/redirectToApplication';

export const redirectAfterLogin = ({
  data: { mfaRequired, hasTotpSecret },
}: AuthApi.LoginEntry) => {
  const urlParams = new URLSearchParams(window.location.search);
  const referrer = urlParams.get('referrer');

  if (mfaRequired) {
    const referrerUrlParam = referrer ? `?referrer=${referrer}` : '';
    const targetUrl = `${hasTotpSecret ? VERIFY_MFA : SETUP_MFA}${referrerUrlParam}`;
    history.push(targetUrl);
  } else {
    redirectToApplication();
  }
};
