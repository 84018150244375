import React, { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { redirectTo } from 'shared/utils/redirect/redirectTo';
import { Box, Fade, Hidden, Icon, InputAdornment, Link, Typography } from '@mui/material';
import { Button } from 'component/Button/Button';
import { useLoginResponseStore } from 'store/loginResponseStore';
import { LOGIN_PATTERN } from 'app/route';
import { WBInput } from 'component/TextField/TextField.s';
import { colors } from 'app/theme/config';
import { copyValueToClipboard } from 'shared/utils/copyValueToClipboard';
import { TextFieldController } from 'component/TextField/TextFieldController';
import { useOnMfaVerificationSubmit } from 'shared/hooks/useOnMfaVerificationSubmit';

const defaultValues: FieldValues = {
  verificationCode: '',
};

export const SetupMfaPage: React.VFC = () => {
  const FADE_TIMEOUT = 300;
  const COPIED_TICK_TIME = FADE_TIMEOUT + 3000;
  const [copied, setCopied] = useState(false);
  const loginResponse = useLoginResponseStore((state) => state.loginResponse);
  const { totpSecret, qrCodeBase64, mfaRequired } = loginResponse?.data || {};

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ defaultValues });

  const onMfaVerificationSubmit = useOnMfaVerificationSubmit();

  if (!mfaRequired || !totpSecret || !qrCodeBase64) {
    redirectTo(LOGIN_PATTERN);
    return null;
  }

  const handleCopyToClipboard = () => {
    if (totpSecret) {
      copyValueToClipboard(totpSecret);
      setCopied(true);
      setTimeout(() => setCopied(false), COPIED_TICK_TIME);
    }
  };

  const onSubmit = async ({ verificationCode }: FieldValues) =>
    onMfaVerificationSubmit(verificationCode);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Typography variant="h2" mb={2} data-testid="setupMfaHeader">
        Multi-factor Authentication
      </Typography>
      <Typography variant="body1" paragraph data-testid="setupMfaDescription">
        Follow these steps to set up multi factor authentication with an authenticator app:
      </Typography>

      <Typography variant="h5" data-testid="setupMfaStep[1]">
        1. Download an authenticator app
      </Typography>

      <Typography variant="body1">
        <Icon sx={{ verticalAlign: 'text-bottom' }}>apple</Icon> iOS devices:{' '}
        <Link href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">
          Google Authenticator
        </Link>
        ,{' '}
        <Link href="https://apps.apple.com/us/app/twilio-authy/id494168017" target="_blank">
          Authy
        </Link>
        ,{' '}
        <Link href="https://apps.apple.com/us/app/duo-mobile/id422663827" target="_blank">
          Duo
        </Link>
      </Typography>
      <Typography variant="body1" paragraph>
        <Icon sx={{ verticalAlign: 'bottom' }}>android</Icon> Android devices:{' '}
        <Link
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
          target="_blank"
        >
          Google Authenticator
        </Link>
        ,{' '}
        <Link href="https://play.google.com/store/apps/details?id=com.authy.authy" target="_blank">
          Authy
        </Link>
        ,{' '}
        <Link
          href="https://play.google.com/store/apps/details?id=com.duosecurity.duomobile"
          target="_blank"
        >
          Duo
        </Link>
      </Typography>

      <Typography paragraph>
        If you do not have access to a smartphone you can use the{' '}
        <Link href="https://authy.com/download/" target="_blank">
          Authy desktop app
        </Link>
        .
      </Typography>

      <Typography variant="h5" data-testid="setupMfaStep[2]">
        <Hidden smDown>2. Scan this QR code with your authenticator app</Hidden>
        <Hidden smUp>2. Enter this code on your authenticator app</Hidden>
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          marginTop: 1,
          marginBottom: 2,
          justifyContent: { xs: 'center', sm: 'space-between' },
        }}
      >
        <Box
          component="img"
          sx={{
            width: '121px',
            height: '121px',
            flexShrink: 0,
            mb: { xs: '22px', sm: '0' },
            mr: { xs: 0, sm: '22px' },
          }}
          alt="setup Mfa QR code"
          src={qrCodeBase64}
        />
        <WBInput
          value={totpSecret}
          fullWidth
          readOnly
          data-testid="codeForAuthAppField"
          sx={{ cursor: 'pointer' }}
          endAdornment={
            <InputAdornment position="end">
              <Box sx={{ width: 24, height: 24, position: 'relative', color: colors.oxfordBlue }}>
                <Fade in={!copied} timeout={FADE_TIMEOUT}>
                  <Icon
                    sx={{ position: 'absolute' }}
                    onClick={handleCopyToClipboard}
                    data-testid="copyToClipboardIcon"
                  >
                    content_copy
                  </Icon>
                </Fade>
                <Fade in={copied} timeout={FADE_TIMEOUT} unmountOnExit>
                  <Icon sx={{ position: 'absolute' }}>check_circle_outline</Icon>
                </Fade>
              </Box>
            </InputAdornment>
          }
        />
      </Box>

      <Typography variant="h5" data-testid="setupMfaStep[3]">
        3. Enter the 6 digit verification code from the app
      </Typography>
      <Box mb={3}>
        <TextFieldController
          control={control}
          name="verificationCode"
          rules={{
            required: { message: 'Code is required', value: true },
          }}
        />
      </Box>
      <Button
        disabled={isSubmitting}
        type="submit"
        fullWidth
        variant="contained"
        data-testid="verifyAccountButton"
      >
        Verify account
      </Button>
    </form>
  );
};
