import React, { useState } from 'react';

import { AvailableSSOProviderDto } from 'api/ssoProviderApi/getAvailableSSOProvider/getAvailableSSOProvider';
import { EmailForm } from 'view/LoginFormPage/EmailForm';
import { LoginForm } from 'view/LoginFormPage/LoginForm';

export const LoginFormPage: React.VFC = () => {
  const [email, setEmail] = useState<string>('');
  const [availableSSOProviders, setAvailableSSOProviders] = useState<AvailableSSOProviderDto[]>([]);

  const onEmailSubmit = (data: {
    email: string;
    availableSSOProviders: AvailableSSOProviderDto[];
  }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const referrer = urlParams.get('referrer');

    if (referrer) {
      sessionStorage.setItem('referrer', referrer);
    }

    setEmail(data.email);
    setAvailableSSOProviders(data.availableSSOProviders);
  };

  const onResetEmail = () => {
    setEmail('');
    setAvailableSSOProviders([]);
  };

  const isEmailFilledAndChecked = !!email;

  return (
    <>
      {isEmailFilledAndChecked ? (
        <LoginForm
          email={email}
          availableSSOProviders={availableSSOProviders}
          onResetEmail={onResetEmail}
        />
      ) : (
        <EmailForm onEmailSubmit={onEmailSubmit} />
      )}
    </>
  );
};
