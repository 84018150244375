import { useEffect, useState } from 'react';

import axios from 'axios';
import { getAppConfig } from 'app/getConfig';
import { growthbook } from 'config/growthbook';
import { FeatureDefinition } from '@growthbook/growthbook-react';

export const useFetchGrowthbook = () => {
  const GROWTHBOOK_API_KEY = getAppConfig().GROWTHBOOK_API_KEY;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    axios
      .get(`https://cdn.growthbook.io/api/features/${GROWTHBOOK_API_KEY}`)
      .then(({ data }) => {
        const features: Record<string, FeatureDefinition> = data.features;
        growthbook.setFeatures(features);
      })
      .catch((e: string) => setError(e))
      .finally(() => setLoading(false));
  }, [GROWTHBOOK_API_KEY]);

  return { loading, error };
};
