import { authApi } from 'container/authApi';
import { redirectToApplication } from 'shared/utils/redirect/redirectToApplication';
import { FORBIDDEN } from 'http-status-codes';
import { useSnackbar } from 'notistack';

export const useOnMfaVerificationSubmit = () => {
  const { enqueueSnackbar } = useSnackbar();

  return (verificationCode: string) => {
    authApi
      .verifyMfa(verificationCode)
      .then(redirectToApplication)
      .catch((error) => {
        if (error.status === FORBIDDEN && error.data?.error) {
          enqueueSnackbar(error.data.error, { variant: 'error' });
        } else if (error.data?.error?.message) {
          enqueueSnackbar(error.data.error.message, { variant: 'error' });
        } else {
          enqueueSnackbar('Cannot login - error occurred.', { variant: 'error' });
        }
      });
  };
};
