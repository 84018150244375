import { useEffect } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { getAppConfig } from 'app/getConfig';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

export const useInitializeDataDogLogs = () => {
  const isRUMEnabled = useFeatureIsOn('datadog-logs-fe');
  const { DATADOG_AUTH_UI_CLIENT_TOKEN, NODE_ENV } = getAppConfig();

  const isAllowedToInit = isRUMEnabled && DATADOG_AUTH_UI_CLIENT_TOKEN;

  useEffect(() => {
    if (isAllowedToInit) {
      datadogLogs.init({
        clientToken: DATADOG_AUTH_UI_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        service: 'auth-ui',
        env: NODE_ENV,
        sessionSampleRate: 100,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: 'all',
      });
    } else {
      datadogLogs.logger.error('datadogLogs failed to init', {
        env: NODE_ENV,
        service: 'auth-ui',
        DATADOG_AUTH_UI_CLIENT_TOKEN: !!DATADOG_AUTH_UI_CLIENT_TOKEN,
        isRUMEnabled,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
