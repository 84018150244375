import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { Theme } from '@mui/material/styles';

type BaseSxItem = boolean | SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);

type SxItem = BaseSxItem | ReadonlyArray<BaseSxItem>;

const isSxValue = (sx: SxItem | undefined): sx is SxItem => !!sx;

export const mergeSx = (
  ...sxList: ReadonlyArray<SxItem | undefined>
): ReadonlyArray<BaseSxItem> => {
  return sxList.filter(isSxValue).flatMap((sxItem) => sxItem);
};
