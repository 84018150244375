import { ThemeOptions } from '@mui/material/styles';

import { breakpoints, colors, typography } from './config';

export const baseThemeOptions: ThemeOptions = {
  breakpoints,
  spacing: 8,
  typography,
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          backgroundColor: colors.zumthor,
        },
      },
    },
  },
};
