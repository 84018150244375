import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { colors, typography } from 'app/theme/config';

export const primaryLinkStyles: SxProps<Theme> = {
  color: colors.blueRibbon,
  display: 'block',
  fontWeight: typography.fontWeightBold,
  mb: 3,
  textDecoration: 'none',
};
