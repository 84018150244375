import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { Box, Button, Typography } from '@mui/material';
import { password } from 'shared/utils/rules/password';
import { TextFieldController } from 'component/TextField/TextFieldController';

const defaultValues: FieldValues = {
  password: '',
  confirmPassword: '',
};

export type ResetPasswordFormProps = {
  buttonText: string;
  onSubmit: (data: FieldValues) => void;
  title: string;
};

export const ResetPasswordForm: React.VFC<ResetPasswordFormProps> = ({
  buttonText,
  onSubmit,
  title,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm({ defaultValues });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box mb={5}>
        <Typography variant="h1">{title}</Typography>
      </Box>
      <TextFieldController
        control={control}
        label="Password"
        name="password"
        rules={password}
        type="password"
      />
      <TextFieldController
        control={control}
        label="Password Confirmation"
        name="confirmPassword"
        rules={{
          required: {
            message: 'Please confirm your password',
            value: true,
          },
          validate: (value) => value === watch('password') || 'Passwords should be the same',
        }}
        type="password"
      />
      <Button disabled={isSubmitting} fullWidth type="submit">
        {buttonText}
      </Button>
    </form>
  );
};
