import { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { getAppConfig } from 'app/getConfig';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { DefaultPrivacyLevel } from '@datadog/browser-core';

export const useInitializeRUM = () => {
  const isRUMEnabled = useFeatureIsOn('datadog-rum');
  const {
    NODE_ENV,
    DATADOG_AUTH_UI_CLIENT_TOKEN,
    DATADOG_AUTH_UI_RUM_APPLICATION_ID,
    DATADOG_AUTH_UI_RUM_DEFAULT_PRIVACY_LEVEL,
    DATADOG_AUTH_UI_RUM_SESSION_REPLAY_SAMPLE_RATE,
    DATADOG_AUTH_UI_RUM_SESSION_SAMPLE_RATE,
    ADMIN_API_CLIENT_URL,
    AUTH_API_CLIENT_URL,
  } = getAppConfig();

  const isAllowedToInit =
    isRUMEnabled && DATADOG_AUTH_UI_RUM_APPLICATION_ID && DATADOG_AUTH_UI_CLIENT_TOKEN;

  useEffect(() => {
    if (isAllowedToInit) {
      datadogRum.init({
        applicationId: DATADOG_AUTH_UI_RUM_APPLICATION_ID,
        clientToken: DATADOG_AUTH_UI_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        service: 'auth-ui',
        env: NODE_ENV,
        defaultPrivacyLevel: DATADOG_AUTH_UI_RUM_DEFAULT_PRIVACY_LEVEL as DefaultPrivacyLevel,
        sessionReplaySampleRate: parseInt(DATADOG_AUTH_UI_RUM_SESSION_REPLAY_SAMPLE_RATE),
        sessionSampleRate: parseInt(DATADOG_AUTH_UI_RUM_SESSION_SAMPLE_RATE),
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        allowedTracingUrls: [ADMIN_API_CLIENT_URL, AUTH_API_CLIENT_URL],
      });

      datadogRum.startSessionReplayRecording();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
