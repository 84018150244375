import { createTheme, ThemeOptions } from '@mui/material/styles';
import { mergeStrict } from 'shared/utils/merge/mergeStrict';
import { common } from '@mui/material/colors';

import { baseThemeOptions } from './base';
import { colors, typography } from './config';

const spacing = createTheme({
  spacing: 8,
}).spacing;

const palette = createTheme({
  palette: {
    error: {
      main: colors.monza,
    },
    primary: {
      main: colors.blueRibbon,
    },
    text: {
      primary: colors.oxfordBlue,
    },
    success: {
      main: colors.greenHaze,
    },
  },
}).palette;

const themeOptions: ThemeOptions = {
  components: {
    MuiLink: {
      defaultProps: {
        color: colors.oxfordBlue,
        fontWeight: typography.fontWeightMedium,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...typography.h6,
          borderRadius: '6px',
          padding: `${spacing(1.5)} ${spacing(2)}`,
          '&:focus': {
            outline: `${colors.cornflowerBlue} solid 3px`,
          },
          '&:disabled': {
            opacity: 0.3,
          },
        },
        containedPrimary: {
          '&': {
            background: `linear-gradient(90deg, ${colors.blueRibbon} 0%, ${colors.electricViolet} 100%)`,
            color: common.white,
          },
          '&:disabled': {
            color: common.white,
          },
          '&:hover': {
            background: `linear-gradient(0deg, rgba(58, 66, 84, 0.5), rgba(58, 66, 84, 0.5)), linear-gradient(90deg, ${colors.blueRibbon} 0%, ${colors.electricViolet} 100%)`,
          },
        },
        text: {
          '&, &:disabled': {
            color: colors.blueRibbon,
          },
          '&:hover': {
            background: colors.snuff,
          },
        },
      },
    },
  },
  palette,
};

export const theme = createTheme(mergeStrict<ThemeOptions>(baseThemeOptions, themeOptions));
