import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { colors } from 'app/theme/config';

export const logoStyles: SxProps<Theme> = {
  height: 40,
  margin: '30px 0 0 16px',
};

export const orangeTriangleStyles: SxProps<Theme> = {
  content: '""',
  display: 'block',
  backgroundImage: `linear-gradient(90deg, #e9a039 0%, #d85636 100%)`,
  clipPath: 'polygon(100% 0, 100% 100%, 0 100%, 0 80%)',
  width: '100%',
  height: '50px',
  position: 'absolute',
  bottom: 0,
  left: 0,
};

export const rightColumnStyles: SxProps<Theme> = {
  backgroundImage: `linear-gradient(90deg, ${colors.blueRibbon} 0%, ${colors.electricViolet} 83.86%)`,
  borderRadius: '80px 0px 0px 0px',
  height: '100%',
  position: 'fixed',
  right: 0,

  ':after': orangeTriangleStyles,
};

export const rightColumnInnerStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  marginX: '16px',
};

export const contentWrapperStyles: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 'calc(100svh - 190px)',
};

export const contentStyles: SxProps<Theme> = {
  width: '100%',
  margin: '0 auto',
  maxWidth: 518,
  padding: '0 16px',
};

export const feedbackWrapperMobileStyles: SxProps<Theme> = {
  display: { xs: 'flex', lg: 'none' },
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '40px',
  clipPath: `polygon(0 6%, 100% 0, 100% 100%, 0% 100%)`,
  background: `linear-gradient(90deg, ${colors.blueRibbon} 0%, ${colors.electricViolet} 100%)`,
  textAlign: 'center',
  position: 'relative',

  ':after': orangeTriangleStyles,
};

export const feedbackContentMobileStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginX: '16px',
  maxWidth: 518,
};

export const addReviewContentStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  paddingTop: '15vh',
};

export const heroesImageStyles: SxProps<Theme> = {
  width: '100%',
  zIndex: 1,
};
