import React from 'react';

import { MicrosoftSSOButton } from 'view/LoginFormPage/SSOButtonList/MicrosoftSSOButton';
import { SSOProviderEnum } from 'shared/enums/SSOProviderEnum';
import { AvailableSSOProviderDto } from 'api/ssoProviderApi/getAvailableSSOProvider/getAvailableSSOProvider';
import { Typography, Divider } from '@mui/material';

type SSOButtonProps = {
  availableSSOProviders: AvailableSSOProviderDto[];
};

const SUPPORTED_PROVIDER = [SSOProviderEnum.MICROSOFT];

export const SSOButtonList: React.VFC<SSOButtonProps> = ({ availableSSOProviders }) => {
  if (
    !availableSSOProviders.some(({ provider }) =>
      SUPPORTED_PROVIDER.some((supportedProvider) => supportedProvider === provider)
    )
  ) {
    return null;
  }

  const renderSSOButton = (ssoProvider: AvailableSSOProviderDto) => {
    switch (ssoProvider.provider) {
      case SSOProviderEnum.MICROSOFT:
        return <MicrosoftSSOButton link={ssoProvider.link} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Typography sx={{ marginBottom: 4 }}>Please login to your account.</Typography>
      {availableSSOProviders.map((ssoProvider) => (
        <React.Fragment key={ssoProvider.provider}>{renderSSOButton(ssoProvider)}</React.Fragment>
      ))}
      <Divider sx={{ my: 4 }}>or</Divider>
    </>
  );
};
