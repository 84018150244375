import { useEffect, useState } from 'react';

import { authApi } from 'container/authApi';
import { redirectToApplication } from 'shared/utils/redirect/redirectToApplication';

export const usePostOAuthCallback = (
  provider: string,
  code: string | null,
  state: string | null,
  redirect: string
) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    authApi
      .oAuthCallback(provider, code, state, redirect)
      .then(() => redirectToApplication())
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [code, provider, redirect, state]);

  return { loading, error };
};
