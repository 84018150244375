import axios, { AxiosError, AxiosInstance } from 'axios';
import { getAppConfig } from 'app/getConfig';
import { datadogLogs } from '@datadog/browser-logs';

export const authApiClient: AxiosInstance = axios.create({
  baseURL: getAppConfig().AUTH_API_CLIENT_URL,
  withCredentials: true,
  // `headers` are custom headers to be sent
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en-GB',
    Accept: 'application/json',
  },
});

authApiClient.interceptors.response.use(undefined, (error: AxiosError) => {
  const message = `[Axios] ${error?.response?.status ?? ''} Error Intercepted`;

  datadogLogs.logger.error(message, { error });

  return Promise.reject(error);
});
