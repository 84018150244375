import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { colors } from 'app/theme/config';
import background from 'app/asset/background.png';

const LOGO_TOP_MARGIN = 30;
const LOGO_HEIGHT = 40;

const IMAGE_HEIGHT = 396;
const IMAGE_WIDTH = 756;

export const logoStyles: SxProps<Theme> = {
  height: LOGO_HEIGHT,
  marginTop: `${LOGO_TOP_MARGIN}px`,
};

export const gridStyles: SxProps<Theme> = {
  height: '100%',
};

export const leftColumnStyles: SxProps<Theme> = (theme) => ({
  paddingLeft: '40px',
  paddingRight: '40px',
  [theme.breakpoints.down('sm')]: {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    paddingBottom: `calc(${IMAGE_HEIGHT} / ${IMAGE_WIDTH} * 100%)`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
});

export const leftColumnInnerStyles: SxProps<Theme> = {
  minHeight: `calc(100% - (${LOGO_HEIGHT}px + ${LOGO_TOP_MARGIN}px))`,
};

export const rightColumnStyles: SxProps<Theme> = {
  backgroundImage: `url(${background}), linear-gradient(90deg, ${colors.blueRibbon} 0%, ${colors.electricViolet} 83.86%)`,
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  borderRadius: '80px 0px 0px 0px',
  height: '100%',
  paddingBottom: `calc(${IMAGE_HEIGHT} / ${IMAGE_WIDTH} * 100% / 2)`,
  position: 'fixed',
  right: 0,
  width: '100%',
};

export const rightColumnInnerStyles: SxProps<Theme> = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  margin: '0 auto',
  minHeight: '100%',
  maxWidth: 562,
};

export const componentWrapper: SxProps<Theme> = {
  margin: '0 auto',
  maxWidth: 518,
};
