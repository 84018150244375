import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { colors } from 'app/theme/config';
import { common } from '@mui/material/colors';
import SegoeUiRegularWoff2 from 'app/asset/segoe-ui.woff2';

const segoeUIRegularFont = {
  fontFamily: 'Segoe UI Regular',
  src: `url(${SegoeUiRegularWoff2}) format('woff2')`,
};

export const buttonStyles: SxProps<Theme> = {
  '@font-face': segoeUIRegularFont,
  fontFamily: 'Segoe UI Regular',
  fontSize: '14px',
  letterSpacing: '.04rem',
  fontWeight: 600,
  color: colors.scorpion,
  background: common.white,
  border: `1px solid ${colors.gray}`,
  padding: '0 12px',
  width: '100%',
  height: '41px',
};

export const iconStyles: SxProps<Theme> = {
  paddingRight: '12px',
};
