import React from 'react';
import { FieldValues } from 'react-hook-form';

import { BAD_REQUEST, FORBIDDEN } from 'http-status-codes';
import { useSnackbar } from 'notistack';
import { AxiosResponse } from 'axios';
import { getResetPasswordToken } from 'shared/utils/getResetPasswordToken';
import { ResetPasswordForm } from 'component/ResetPasswordForm/ResetPasswordForm';
import { authApi } from 'container/authApi';

export const ResetPasswordPage: React.VFC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const token = getResetPasswordToken();

  const handleSubmit = async (data: FieldValues) => {
    await authApi
      .resetPassword(data.password, data.confirmPassword, token)
      .then(() => {
        window.location.href = window.location.origin;
      })
      .catch((error: AxiosResponse) => {
        if (error.status === FORBIDDEN && error.data?.error) {
          enqueueSnackbar(error.data.error, { variant: 'error' });
        } else if (error.status === BAD_REQUEST && error.data?.error?.violations) {
          const multiLineMessage = error.data.error.violations
            .map((violation: { message: string }) => violation.message)
            .join('\n');
          enqueueSnackbar(multiLineMessage, {
            variant: 'error',
            SnackbarProps: { style: { whiteSpace: 'pre-line' } },
          });
        } else {
          enqueueSnackbar('Cannot set password - error occurred.', { variant: 'error' });
        }
      });
  };

  return (
    <ResetPasswordForm
      buttonText="Reset Password"
      onSubmit={handleSubmit}
      title="Change password"
    />
  );
};
