import { ROOT } from 'app/route';
import { redirectTo } from 'shared/utils/redirect/redirectTo';

export const referrerIsValid = (referrer: string): boolean => {
  if (referrer.startsWith('/') && !referrer.startsWith('//')) {
    return true;
  }
  /*
  This regular expression matches URLs that start with either "https://" or "http://", followed by an optional
  subdomain (e.g., "www."), then "workbuzz.com" or "workbuzz.wb/", where "com" or "wb"
  are valid top-level domains.

  Breakdown of the regular expression:
  - /^: Anchors the match at the start of the string.
  - (https:|http:)?: Matches either "https://" or "http://", the "?" makes it optional.
  - \/\/: Matches the double forward slashes after "http://" or "https://".
  - ([a-zA-Z0-9\-_.]+\.)?: Matches an optional subdomain consisting of letters,
    numbers, hyphens, underscores, and periods followed by a dot.
  - workbuzz\. : Matches the literal text "workbuzz.".
  - (com|wb): Matches either "com" or "wb" as the top-level domain.
  - \/: Matches the trailing forward slash.
  */

  return !!referrer.match(/^(https:|http:)?\/\/([a-zA-Z0-9\-_.]+\.)?workbuzz\.(com|wb)\//);
};
export const redirectToApplication = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let referrer = urlParams.get('referrer');

  if (!referrer) {
    referrer = sessionStorage.getItem('referrer');
  }

  sessionStorage.removeItem('referrer');

  if (referrer && referrerIsValid(referrer)) {
    redirectTo(referrer);
  } else if (window.location.pathname !== ROOT) {
    redirectTo(ROOT);
  }
};
