import { useForm } from 'react-hook-form';
import React from 'react';

import { AvailableSSOProviderDto } from 'api/ssoProviderApi/getAvailableSSOProvider/getAvailableSSOProvider';
import { TextFieldController } from 'component/TextField/TextFieldController';
import { email } from 'shared/utils/rules/email';
import { Box, Button, Typography } from '@mui/material';
import { ssoProviderApi } from 'api/ssoProviderApi/ssoProviderApi';
import { useSnackbar } from 'notistack';

type EmailFormProps = {
  onEmailSubmit: ({
    email,
    availableSSOProviders,
  }: {
    email: string;
    availableSSOProviders: AvailableSSOProviderDto[];
  }) => void;
};

type EmailFormFieldValues = {
  email: string;
};

export const EmailForm: React.VFC<EmailFormProps> = ({ onEmailSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<EmailFormFieldValues>({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async ({ email }: EmailFormFieldValues) => {
    const uiAppBaseUrl = location.origin;
    return ssoProviderApi
      .getAvailableSSOProviders({ email, uiAppBaseUrl })
      .then((availableSSOProviders) => {
        onEmailSubmit({ email, availableSSOProviders });
      })
      .catch(() => {
        enqueueSnackbar('Cannot continue - error occurred.', { variant: 'error' });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="emailForm" noValidate>
      <Box mb={5}>
        <Typography variant="h1">Welcome to WorkBuzz</Typography>
      </Box>
      <TextFieldController
        control={control}
        label="Email Address"
        name="email"
        rules={email}
        type="email"
      />
      <Button disabled={isSubmitting} fullWidth type="submit">
        Continue
      </Button>
    </form>
  );
};
