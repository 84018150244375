import React, { forwardRef } from 'react';

import { Alert, SnackbarContent } from '@mui/material';
import { CustomContentProps, SnackbarContent as NotistackContent } from 'notistack';

type SnackbarAlertProps = CustomContentProps;

export const SnackbarAlert = forwardRef<HTMLDivElement, SnackbarAlertProps>((props, ref) => {
  const { message, id, variant } = props;

  let ContentElement: JSX.Element;
  if (variant === 'default') {
    ContentElement = <SnackbarContent message={message} />;
  } else {
    ContentElement = <Alert severity={variant}>{message}</Alert>;
  }

  return (
    <NotistackContent ref={ref} id={id.toString()}>
      {ContentElement}
    </NotistackContent>
  );
});
