import { TypographyVariantsOptions } from '@mui/material';
import { createBreakpoints } from '@mui/system';

const htmlFontSize = 16;

// Use theme.typography.pxToRem if possible
const pxToRem = (px: number) => `${px / htmlFontSize}rem`;

// That config will be injected in theme, use theme.breakpoints if possible
export const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 902,
    lg: 1200,
    xl: 1536,
  },
});

// https://chir.ag/projects/name-that-color/
export const colors = {
  blueRibbon: '#0150FF',
  cornflowerBlue: '#5383EC',
  electricViolet: '#7501FF',
  iron: '#D8D9DD',
  monza: '#DD0606',
  oxfordBlue: '#3A4254',
  snuff: '#DFE3F1',
  greenHaze: '#00A84F',
  scorpion: '#5E5E5E',
  gray: '#8C8C8C',
  zumthor: '#E6EEFF',
};

const typographyFontWeight: TypographyVariantsOptions = {
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
};

// That config will be injected in theme, use theme.typography if possible
export const typography: TypographyVariantsOptions = {
  ...typographyFontWeight,
  htmlFontSize,
  allVariants: {
    lineHeight: 1.4,
  },
  fontFamily: ['Montserrat', '-apple-system', 'BlinkMacSystemFont', 'Arial', 'sans-serif'].join(
    ','
  ),
  button: {
    textTransform: 'none',
  },
  h1: {
    fontSize: pxToRem(28.83),
    fontWeight: typographyFontWeight.fontWeightBold,
    lineHeight: pxToRem(40.3),
    [breakpoints.up('md')]: {
      fontSize: pxToRem(39.81),
      lineHeight: pxToRem(55.7),
    },
  },
  h2: {
    fontSize: pxToRem(25.63),
    fontWeight: typographyFontWeight.fontWeightBold,
    lineHeight: pxToRem(35),
    [breakpoints.up('md')]: {
      fontSize: pxToRem(33.18),
      lineHeight: pxToRem(46.4),
    },
  },
  h3: {
    fontSize: pxToRem(22.78),
    fontWeight: typographyFontWeight.fontWeightBold,
    lineHeight: pxToRem(31.8),
    [breakpoints.up('md')]: {
      fontSize: pxToRem(27.65),
      lineHeight: pxToRem(38.7),
    },
  },
  h4: {
    fontSize: pxToRem(20.25),
    fontWeight: typographyFontWeight.fontWeightBold,
    lineHeight: pxToRem(28.3),
    [breakpoints.up('md')]: {
      fontSize: pxToRem(23.04),
      lineHeight: pxToRem(32),
    },
  },
  h5: {
    fontSize: pxToRem(18),
    fontWeight: typographyFontWeight.fontWeightBold,
    lineHeight: pxToRem(25.2),
    [breakpoints.up('md')]: {
      fontSize: pxToRem(19.2),
      lineHeight: pxToRem(26.8),
    },
  },
  h6: {
    fontSize: pxToRem(16),
    fontWeight: typographyFontWeight.fontWeightBold,
    lineHeight: pxToRem(22.4),
  },
  subtitle1: {
    fontSize: pxToRem(16),
    fontWeight: typographyFontWeight.fontWeightBold,
    lineHeight: pxToRem(22.4),
  },
  subtitle2: {
    fontSize: pxToRem(13.3),
    fontWeight: typographyFontWeight.fontWeightBold,
    lineHeight: pxToRem(18.6),
  },
  body1: {
    fontSize: pxToRem(16),
    fontWeight: typographyFontWeight.fontWeightRegular,
    lineHeight: pxToRem(24),
  },
  body2: {
    fontSize: pxToRem(12.64),
    fontWeight: typographyFontWeight.fontWeightRegular,
    lineHeight: pxToRem(18.96),
    [breakpoints.up('md')]: {
      fontSize: pxToRem(13.33),
      lineHeight: pxToRem(19.9),
    },
  },
};
