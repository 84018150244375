import { AxiosInstance } from 'axios';

export namespace AuthApi {
  export type ResetPasswordEntry = {
    isAdmin: boolean;
  };

  export type LoginEntry = {
    data: {
      mfaRequired: boolean;
      hasTotpSecret?: boolean;
      totpSecret?: string;
      qrCodeBase64?: string;
      message?: string;
    };
  };
}

export class AuthApi {
  constructor(private authApi: AxiosInstance) {}

  login(
    email: string,
    password: string,
    token: string | null | undefined
  ): Promise<AuthApi.LoginEntry> {
    return this.authApi
      .post('/api/auth/login', { email, password, token })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response;
      });
  }

  resetPasswordRequest(email: string): Promise<unknown> {
    return this.authApi.post(`/api/user/reset-password-request/${email}`).catch((error) => {
      throw error.response;
    });
  }

  resetPassword(
    password: string,
    confirmPassword: string,
    token: string
  ): Promise<AuthApi.ResetPasswordEntry> {
    return this.authApi
      .put(`/api/user/reset-password/${token}`, { password, confirmPassword })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response;
      });
  }

  verifyMfa(verificationCode: string): Promise<unknown> {
    return this.authApi
      .post('/api/auth/verify-mfa', { verificationCode })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response;
      });
  }

  oAuthCallback(provider: string, code: string | null, state: string | null, uiAppBaseUrl: string) {
    return this.authApi
      .post(`/api/oauth/callback/${provider}`, { code, state, uiAppBaseUrl })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response;
      });
  }
}
